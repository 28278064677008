import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { setupAxiosInterceptors } from 'config/axios-interceptor';
import { AuthContextProvider } from 'shared/auth/AuthContext';

const SharedRoutes = lazy(() => import('./views/shared'));
const DashboardRoutes = lazy(() => import('./views/dashboard'));
const AppRoutes = lazy(() => import('./views/app'));

setupAxiosInterceptors();

function App() {
  return (
    <AuthContextProvider>
      <Suspense fallback={<div>Loading...</div>}>
        <BrowserRouter>
          <Switch>
            <Route path="/app" component={AppRoutes} />
            <Route path="/dashboard" component={DashboardRoutes} />
            <Route path="/" component={SharedRoutes} />
          </Switch>
        </BrowserRouter>
      </Suspense>
    </AuthContextProvider>
  );
}

export default App;
