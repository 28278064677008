import axios, { AxiosResponse } from 'axios';
import { SERVER_API_URL } from 'shared/constants/constants';
import { User } from 'models/user';

export const AUTH_API_URL = '/api/authenticate';
export const ACCOUNT_API_URL = '/api/account';

type AuthObject = {
  username: string;
  password: string;
};
type JWTTokenResponse = {
  jwttoken: string;
};

const authenticate = ({ username, password }: AuthObject): Promise<AxiosResponse<JWTTokenResponse>> =>
  axios
    .create({
      baseURL: SERVER_API_URL,
    })
    .post(AUTH_API_URL, { username, password });

const getCurrentUser = (): Promise<AxiosResponse<User>> => axios.get(ACCOUNT_API_URL);

export const AuthenticationApi = {
  authenticate,
  getCurrentUser,
};
