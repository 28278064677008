import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import HttpStatus from 'http-status';

import { SERVER_API_URL } from 'shared/constants/constants';
import { AUTH_TOKEN_KEY } from 'shared/auth/constants';

const TIMEOUT = 2 * 60 * 1000; // time out requests after 2 minutes
axios.defaults.timeout = TIMEOUT;
axios.defaults.baseURL = SERVER_API_URL;

export const setupAxiosInterceptors = () => {
  const onRequestSuccess = (config: AxiosRequestConfig) => {
    const token = localStorage.getItem(AUTH_TOKEN_KEY) || sessionStorage.getItem(AUTH_TOKEN_KEY);
    if (token) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  };
  const onResponseSuccess = (response: AxiosResponse) => response;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onResponseError = (err: any) => {
    const status = err.status || (err.response ? err.response.status : 0);

    if (status === HttpStatus.FORBIDDEN || status === HttpStatus.UNAUTHORIZED) {
      localStorage.removeItem(AUTH_TOKEN_KEY);
      sessionStorage.removeItem(AUTH_TOKEN_KEY);
    }
    return Promise.reject(err);
  };
  axios.interceptors.request.use(onRequestSuccess);
  axios.interceptors.response.use(onResponseSuccess, onResponseError);
};
