export const SERVER_API_URL = process.env.REACT_APP_SERVER_URL;

export const SERVER_STATIC_FILE_URL = `${SERVER_API_URL}/static`;

export const PAGINATION = {
  DEFAULT_PAGE_SIZE: 50,
  DEFAULT_PAGE_SIZE_OPTIONS: [50, 100, 500, 1000, 5000, 1000000],
};

export const PAGINATION_TOTAL_COUNT_HEADER = 'x-total-count';
export const PAGINATION_CURRENT_PAGE_HEADER = 'x-current-page';

export const HUMAN_READABLE_DATE_FORMAT = 'YYYY-MM-DD HH:mm';

export const SERVER_DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss\\Z';
