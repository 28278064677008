import React, { createContext, ReactNode, useEffect, useState } from 'react';
import { User } from 'models/user';
import { AuthenticationApi } from 'api/authenticationApi';
import { AUTH_TOKEN_KEY } from './constants';

interface AuthContextProps {
  user: User | null;
  loading: boolean;
  error: boolean;
  isAuthenticated: boolean;
  signIn: (username: string, password: string) => Promise<void>;
  signOut: () => void;
  authenticate: (username: string, password: string) => Promise<boolean>;
}

const initialAuthContext: AuthContextProps = {
  user: null,
  loading: false,
  error: false,
  isAuthenticated: false,
  signIn: async () => {},
  signOut: () => {},
  authenticate: async () => false,
};

export const AuthContext = createContext<AuthContextProps>(initialAuthContext);

interface ChildrenProps {
  children: ReactNode;
}

export const AuthContextProvider = (props: ChildrenProps) => {
  const { children } = props;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    setLoading(true);
    AuthenticationApi.getCurrentUser()
      .then((res) => setUser(res.data))
      .finally(() => setLoading(false));
  }, []);

  const authenticate = async (username: string, password: string) => {
    try {
      await AuthenticationApi.authenticate({ username, password });
      return true;
    } catch (e) {
      return false;
    }
  };

  const signIn = async (username: string, password: string) => {
    setLoading(true);
    try {
      const jwtResult = await AuthenticationApi.authenticate({ username, password });
      const jwtToken = jwtResult.data.jwttoken;
      if (jwtToken) {
        localStorage.setItem(AUTH_TOKEN_KEY, jwtToken);
        sessionStorage.setItem(AUTH_TOKEN_KEY, jwtToken);
      }

      const userResult = await AuthenticationApi.getCurrentUser();
      setUser(userResult.data);
    } catch (e) {
      setError(true);
    }
    setLoading(false);
  };

  const signOut = () => {
    setUser(null);
    localStorage.removeItem(AUTH_TOKEN_KEY);
    sessionStorage.removeItem(AUTH_TOKEN_KEY);
  };

  return (
    <AuthContext.Provider value={{ user, loading, error, isAuthenticated: !!user, signIn, signOut, authenticate }}>
      {children}
    </AuthContext.Provider>
  );
};
